
module.exports = global.config = {
    ipv4: "http://3.144.3.136:5000"
    // other global config variables you wish
};





